import React from "react";
import { FormattedMessage } from "react-intl";

import {
  singleThreatSort,
  timeSort
} from "../../../../components/table/helpers/sorting.helper";
import { CardWithCount } from "../tableCards/CardWithCount";
import { CardWithThreats } from "../tableCards/CardWithThreats";

export const MOBILE_SUBROWS_SINGLE = {
  webfilter: [
    {
      accessor: "reason",
      header: <FormattedMessage id={"reason_drilldown_header"} />
    }
  ]
};

export const MOBILE_SUBROWS = {
  webfilter: [
    {
      accessor: "reason",
      header: <FormattedMessage id={"reason_drilldown_header"} />
    },
    {
      accessor: "device",
      header: <FormattedMessage id={"device_drilldown_header"} />
    }
  ],
  malware: [
    {
      accessor: "device",
      header: <FormattedMessage id={"device_drilldown_header"} />
    }
  ],
  botnet: [
    {
      accessor: "url",
      header: <FormattedMessage id={"url_drilldown_header"} />
    }
  ]
};

const COMMON_COLUMNS = [
  {
    accessor: "url",
    SortingLabel: <FormattedMessage id={"url_drilldown_header"} />
  },
  {
    accessor: "time",
    SortingLabel: <FormattedMessage id={"time_drilldown_header"} />,
    sortType: timeSort
  },
  {
    accessor: "count",
    SortingLabel: <FormattedMessage id={"count_drilldown_header"} />
  }
];

const DEVICE_COLUMN = [
  {
    accessor: "device",
    SortingLabel: <FormattedMessage id={"device_drilldown_header"} />
  }
];
const REASON_COLUMN = [
  {
    accessor: "reason",
    SortingLabel: <FormattedMessage id={"reason_drilldown_header"} />
  }
];

export const MOBILE_HIDDEN_COLUMNS = {
  webfilter: ["url", "time", "count", "device", "reason"],
  malware: ["url", "time", "count", "device"],
  botnet: ["url", "time", "count", "device"]
};

export const MOBILE_COLUMNS_SINGLE = {
  webfilter: [
    {
      accessor: "categories",
      SortingLabel: <FormattedMessage id={"categories_drilldown_header"} />,
      Cell: ({ row }) => {
        const {
          original: { count, url, categories, time },
          isExpanded
        } = row;

        return (
          <CardWithCount
            isExpanded={isExpanded}
            count={count}
            url={url}
            content={categories}
            time={time}
          />
        );
      }
    },
    ...REASON_COLUMN,
    ...COMMON_COLUMNS
  ],
  malware: [
    {
      accessor: "reason",
      SortingLabel: <FormattedMessage id={"reason_drilldown_header"} />,
      Cell: ({ row }) => {
        const {
          original: { count, url, reason, time }
        } = row;

        return (
          <CardWithCount
            showExpand={false}
            count={count}
            url={url}
            content={reason}
            time={time}
          />
        );
      }
    },
    ...COMMON_COLUMNS
  ],
  botnet: [
    {
      accessor: "threat",
      SortingLabel: <FormattedMessage id={"severity_type_drilldown_header"} />,
      sortType: singleThreatSort,
      Cell: ({ row }) => {
        const { original } = row;
        const id = original["threat-id"];
        const { count, time, severityIndex, url, threat } = original;
        return (
          <CardWithThreats
            showExpand={false}
            count={count}
            time={time}
            severityIndex={severityIndex}
            url={url}
            threat={threat}
            id={id}
          />
        );
      }
    },
    ...COMMON_COLUMNS
  ]
};

export const MOBILE_COLUMNS_MULTIPLE = {
  webfilter: [
    {
      accessor: "categories",
      SortingLabel: <FormattedMessage id={"categories_drilldown_header"} />,
      Cell: ({ row }) => {
        const {
          original: { count, url, categories, time },
          isExpanded
        } = row;

        return (
          <CardWithCount
            isExpanded={isExpanded}
            url={url}
            content={categories}
            time={time}
            count={count}
          />
        );
      }
    },
    ...REASON_COLUMN,
    ...DEVICE_COLUMN,
    ...COMMON_COLUMNS
  ],
  malware: [
    {
      accessor: "reason",
      SortingLabel: <FormattedMessage id={"reason_drilldown_header"} />,
      Cell: ({ row }) => {
        const {
          original: { count, url, reason, time },
          isExpanded
        } = row;

        return (
          <CardWithCount
            isExpanded={isExpanded}
            url={url}
            content={reason}
            time={time}
            count={count}
          />
        );
      }
    },
    ...DEVICE_COLUMN,
    ...COMMON_COLUMNS
  ],
  botnet: [
    {
      accessor: "threat",
      SortingLabel: <FormattedMessage id={"severity_type_drilldown_header"} />,
      sortType: singleThreatSort,
      Cell: ({ row }) => {
        const { original, isExpanded } = row;
        const id = original["threat-id"];
        const { count, device, time, severityIndex, threat } = original;
        return (
          <CardWithThreats
            isExpanded={isExpanded}
            count={count}
            time={time}
            severityIndex={severityIndex}
            device={device}
            threat={threat}
            id={id}
          />
        );
      }
    },
    ...DEVICE_COLUMN,
    ...COMMON_COLUMNS
  ]
};
