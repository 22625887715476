import React from "react";

import { GET_SETTINGS_FAILURE } from "../store/settings/settings.types";
import Notificator from "../components/notification/notification.actions";
import { FormattedMessage } from "react-intl";
import { LOGIN_FAILURE, logout } from "../pages/login/login.actions";
import { LOAD_INFO_FAILURE, WRONG_USER_MODE } from "../store/info/info.types";
import { LOAD_ATTRIBUTES_FAILURE } from "../store/attributes/attributes.actions";
import {
  ACTIVATE_SERVICES_FAILURE,
  CREATE_ACCOUNT_FAILURE
} from "../store/account/account.types";
import { history } from "../router";

// TODO: probably can be implemented as a generic action creator
export const errorsMiddleware = ({ dispatch, getState }) => {
  return next => {
    return action => {
      const {
        config: { showErrorPageBeforeAutoLogout },
        account
      } = getState();

      switch (action.type) {
        case GET_SETTINGS_FAILURE: {
          dispatch(
            Notificator.error(<FormattedMessage id={"settings_failed"} />)
          );
          dispatch(logout());
          break;
        }
        case CREATE_ACCOUNT_FAILURE: {
          if (showErrorPageBeforeAutoLogout) {
            history.push("error/account");
          } else {
            dispatch(
              Notificator.error(
                <FormattedMessage id={"failed_to_create_account"} />
              )
            );
            dispatch(logout());
          }
          return;
        }
        case ACTIVATE_SERVICES_FAILURE: {
          dispatch(
            Notificator.error(
              <FormattedMessage id={"couldnt_activate_services"} />
            )
          );
          dispatch(logout());
          break;
        }
        case LOAD_ATTRIBUTES_FAILURE: {
          dispatch(
            Notificator.error(<FormattedMessage id={"language_load_error"} />)
          );
          dispatch(logout());
          break;
        }
        case LOGIN_FAILURE: {
          dispatch(Notificator.error(<FormattedMessage id={"login_error"} />));
          break;
        }
        case LOAD_INFO_FAILURE: {
          if (isUserLoggedIn(account)) {
            dispatch(
              Notificator.error(<FormattedMessage id={"login_error"} />)
            );
            dispatch(logout());
          }
          break;
        }
        case WRONG_USER_MODE: {
          dispatch(Notificator.error(<FormattedMessage id={"login_error"} />));
          dispatch(logout());
          break;
        }
        default:
          break;
      }

      return next(action);
    };
  };
};

const isUserLoggedIn = account => account && account.id;
