import React from "react";
import { FormattedMessage } from "react-intl";

import {
  singleThreatSort,
  timeSort
} from "../../../../components/table/helpers/sorting.helper";
import { Tooltip } from "../../../../components/tooltip/Tooltip";
import { OriginalUrl } from "../originalUrl/OriginalUrl";
import { SeverityLevel } from "../severityLevel/SeverityLevel";
import { DeviceNameCell } from "../../../../components/table";

export const COMMON_COLUMNS = [
  {
    accessor: "time",
    Header: (
      <Tooltip content={<FormattedMessage id={"time_period_tooltip"} />}>
        <FormattedMessage id={"time_drilldown_header"} />
      </Tooltip>
    ),
    headerClassName: "drilldowns__table__time-cell",
    cellClassName: "table__cell--placeholder",
    sortable: true,
    sortType: timeSort
  },
  {
    accessor: "count",
    Header: (
      <Tooltip content={<FormattedMessage id={"count_tooltip"} />}>
        <FormattedMessage id={"count_drilldown_header"} />
      </Tooltip>
    ),
    headerClassName: "table__cell--right table__cell--small-cell",
    cellClassName: "table__cell--right",
    sortable: true
  }
];

export const DEVICE_COLUMN = [
  {
    Header: <FormattedMessage id={"device_drilldown_header"} />,
    accessor: "device",
    sortable: true,
    Cell: ({ row: { original } }) => <DeviceNameCell name={original.device} />
  }
];

export const COLUMNS_ORDER = {
  webfilter: ["url", "reason", "categories", "device", "time", "count"],
  malware: ["url", "reason", "device", "time", "count"],
  botnet: ["url", "threat", "device", "time", "count"]
};

export const COLUMNS_SINGLE = {
  webfilter: [
    {
      accessor: "categories",
      Header: <FormattedMessage id={"categories_drilldown_header"} />,
      sortable: true,
      Cell: ({ row: { original } }) => original.categories || "-"
    },
    {
      Header: <FormattedMessage id={"reason_drilldown_header"} />,
      accessor: "reason",
      sortable: true
    },
    {
      accessor: "url",
      Header: <FormattedMessage id={"url_drilldown_header"} />,
      sortable: true,
      Cell: ({ row: { original } }) => (
        <OriginalUrl isLink url={original.url} shortUrl={original.shortUrl} />
      )
    },
    ...COMMON_COLUMNS
  ],
  malware: [
    {
      accessor: "reason",
      Header: <FormattedMessage id={"reason_drilldown_header"} />,
      sortable: true
    },
    {
      accessor: "url",
      Header: <FormattedMessage id={"url_drilldown_header"} />,
      sortable: true,
      Cell: ({ row: { original } }) => (
        <OriginalUrl url={original.url} shortUrl={original.shortUrl} />
      )
    },
    ...COMMON_COLUMNS
  ],
  botnet: [
    {
      accessor: "url",
      Header: <FormattedMessage id={"url_drilldown_header"} />,
      sortable: true,
      cellClassName: "cell--url",
      Cell: ({ row: { original } }) => (
        <OriginalUrl url={original.url} shortUrl={original.shortUrl} />
      )
    },
    {
      accessor: "threat",
      Header: <FormattedMessage id={"severity_type_drilldown_header"} />,
      sortable: true,
      sortType: singleThreatSort,
      Cell: ({ row: { original } }) => (
        <SeverityLevel
          threatId={original["threat-id"]}
          severityIndex={original.severityIndex}
          threatTooltip={original["threat-tooltip"]}
          threat={original.threat}
        />
      )
    },
    ...COMMON_COLUMNS
  ]
};
export const COLUMNS_MULTIPLE = {
  webfilter: [...COLUMNS_SINGLE.webfilter, ...DEVICE_COLUMN],
  malware: [...COLUMNS_SINGLE.malware, ...DEVICE_COLUMN],
  botnet: [...COLUMNS_SINGLE.botnet, ...DEVICE_COLUMN]
};

export const SEARCH_KEYS = [
  "categories",
  "count",
  "device",
  "reason",
  "shortUrl",
  "time",

  // botnet
  "name",
  "threat"
];

export const initialSort = {
  id: "time",
  desc: true
};
